import { useLocale } from "@/hooks/useLocale";
import { useTitle } from "@/hooks/useTitle";
import { Link } from "@/router";

export default function Four0Four() {
  useTitle("404");
  const { lang } = useLocale();

  return (
    <div className="pt-24 px-4 sm:px-6 lg:px-8 w-full max-w-7xl mx-auto text-center">
      <h1 className="text-5xl font-semibold tracking-tighter ">Oops... we lost this page.</h1>

      <p className="text-base text-gray-600 mt-6 max-w-xl mx-auto">
        Please use the navigation menu to find what you are looking for, or click the button below to go back to the
        home page.
      </p>

      <Link to={"/:lang?"} params={{ lang }} className="mt-6 btn-primary btn-lg">
        Go Home
      </Link>
    </div>
  );
}
