import { analytics, auth } from "@/firebase";
import { useLocale } from "@/hooks/useLocale";
import { useNavigate } from "@/router";
import { setUserId } from "firebase/analytics";
import { User, onAuthStateChanged } from "firebase/auth";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";

interface AuthContextProps {
  user: User | null;
  authLoaded: boolean; // false if we have not yet checked if user is logged in
}

// Create auth context
const AuthContext = createContext<AuthContextProps>({ user: null, authLoaded: false });

// Export auth context
export default function AuthStore({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<AuthContextProps["user"]>(null);
  const [authLoaded, setAuthLoaded] = useState<AuthContextProps["authLoaded"]>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (import.meta.env.DEV) console.log("DEV: User is logged in!");
        setUser(user);
        /**
         * Add user data to analytics event
         */
        if (analytics) setUserId(analytics, user.uid, { global: true });
      } else {
        if (import.meta.env.DEV) console.log("DEV: User is logged out!");
        setUser(null);
        if (analytics) setUserId(analytics, null, { global: true });
      }

      setAuthLoaded(true);
    });

    return () => unsubscribe();
  });

  return <AuthContext.Provider value={{ user, authLoaded }}>{children}</AuthContext.Provider>;
}

/**
 * Custom hook to use the auth context
 *
 * Will redirect to login page if user is not logged in
 *
 * @returns User
 */
export function useUser_strict() {
  const authContext = useContext(AuthContext);

  if (authContext === undefined) {
    throw new Error("useUser_strict must be used within an AuthProvider");
  }

  if (!authContext.user) {
    throw new Error(
      "User is not logged in and you tried to use useuseUser_strictUser. Use the useIsLoggedIn hook to check if user is logged in before using useUser_strict"
    );
  }

  return authContext.user;
}

/**
 * @returns true if the auth state has been loaded
 */
export function useAuthLoaded() {
  const authContext = useContext(AuthContext);

  if (authContext === undefined) {
    throw new Error("useAuthLoaded must be used within an AuthProvider");
  }

  return authContext.authLoaded;
}

/**
 * @returns true if the user is logged in, false if not
 */
export function useIsLoggedIn() {
  const authContext = useContext(AuthContext);

  if (authContext === undefined) {
    throw new Error("useIsLoggedIn must be used within an AuthProvider");
  }

  return !!authContext.user;
}
