import ModalStore from "@/context/ModalStore";
import { useAuthLoaded } from "@/context/Auth";
import { Modals } from "@generouted/react-router/lazy";
import { Outlet, ScrollRestoration } from "react-router-dom";

export default function App() {
  const authLoaded = useAuthLoaded();

  // delay render until user auth state is loaded
  // auth state is loaded when user is either User or null, not undefined
  if (!authLoaded) {
    return <>{import.meta.env.DEV && <div>Dev mode: Checking auth...</div>}</>;
  }

  return (
    <>
      <ModalStore>
        <Outlet />
        <Modals />
        <ScrollRestoration />
      </ModalStore>
    </>
  );
}
